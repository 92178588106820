<template>
  <div>
    <h2>Page not found</h2>
    <hr />
    <p>Sorry, the requested page does not exist. Please click the "back" button in your browser.</p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
